import React, { useEffect, useState } from "react";
import DynamicTable from "../../components/DynamicTable";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import ErrorMessage from "../../components/ErrorMessage";
import DeleteAllModal from "../../modals/DeleteAllModal";
import DeleteRowModal from "../../modals/DeleteRowModal";
import RestoreRowModal from "../../modals/RestoreRowModal";
import RestoreAllModal from "../../modals/RestoreAllModal";
import { Box, Button, Collapse } from "@mui/material";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import {
  getAction,
  softDeleteAction,
  softDeleteAllAction,
  deleteRowAction,
  deleteAllAction,
  restoreRowAction,
  restoreAllAction,
} from "../../store/actions/quotations";
import { DecodeUserRole, DecodeUsername } from "../../hooks/userData";
const moment = require("moment");

const Quotations = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.quotations.data);
  const loading = useSelector((state) => state.quotations.loading);
  const error = useSelector((state) => state.quotations.error);
  const [showDeleteRowModal, setDeleteRowModal] = useState(false);
  const [showDeleteAllModal, setDeleteAllModal] = useState(false);
  const [showRestoreRowModal, setRestoreRowModal] = useState(false);
  const [showRestoreAllModal, setRestoreAllModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [selectedTable, setSelectedTable] = useState(false);
  const [showDeletedTables, setShowDeletedTables] = useState(false);
  const userRole = DecodeUserRole();
  const username = DecodeUsername();

  useEffect(() => {
    dispatch(getAction());
  }, [dispatch]);

  const filteredActiveData = data?.quotations
    ?.filter((item) => !item.isDeleted)
    .map((item) => {
      const {
        createdAt,
        updatedAt,
        isDeleted,
        deletedBy,
        date,
        deletedAt,
        services,
        ...rest
      } = item;

      const formattedServices = JSON.parse(services);

      const formattedDate = moment(date).format("DD/MM/YYYY hh:mm A");
      return {
        ...rest,
        formattedServices,
        date: formattedDate,
      };
    });

  const filteredDeletedData = data?.quotations
    ?.filter((item) => item.isDeleted)
    .map((item) => {
      const { createdAt, updatedAt, isDeleted, deletedAt, date, ...rest } =
        item;

      const formattedDate = moment(date).format("DD/MM/YYYY hh:mm A");
      const formattedDeletedAt = moment(deletedAt).format("DD/MM/YYYY hh:mm A");

      return {
        ...rest,
        date: formattedDate,
        deletedAt: formattedDeletedAt,
      };
    });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "5em" }}>
      {(userRole === "admin" || userRole === "superadmin") && (
        <>
          <Box>
            {loading ? (
              <Loader />
            ) : error ? (
              <ErrorMessage message={error} />
            ) : (
              <>
                <DynamicTable
                  data={filteredActiveData}
                  setDeleteAllModal={setDeleteAllModal}
                  setDeleteRowModal={setDeleteRowModal}
                  setSelectedRow={setSelectedRow}
                  table={"Quotations"}
                  setSelectedTable={setSelectedTable}
                  canCreate={false}
                  canEdit={false}
                />
              </>
            )}
          </Box>
        </>
      )}
      {userRole === "superadmin" && (
        <>
          <Box>
            <Button onClick={() => setShowDeletedTables(!showDeletedTables)}>
              {showDeletedTables
                ? "Hide Deleted Tables"
                : "Show Deleted Tables"}{" "}
              {showDeletedTables ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </Button>

            <Collapse in={showDeletedTables}>
              {loading ? (
                <Loader />
              ) : error ? (
                <ErrorMessage message={error} />
              ) : (
                <>
                  <RestoreRowModal
                    onClose={() => setRestoreRowModal(false)}
                    showRestoreRowModal={showRestoreRowModal}
                    id={selectedRow?.id}
                    table={selectedTable}
                    action={restoreRowAction}
                    username={username}
                  />

                  <RestoreAllModal
                    onClose={() => setRestoreAllModal(false)}
                    showRestoreAllModal={showRestoreAllModal}
                    table={selectedTable}
                    action={restoreAllAction}
                    username={username}
                  />

                  <DeleteRowModal
                    onClose={() => setDeleteRowModal(false)}
                    showDeleteRowModal={showDeleteRowModal}
                    id={selectedRow?.id}
                    table={selectedTable}
                    action={
                      selectedTable === "Deleted Quotations"
                        ? deleteRowAction
                        : softDeleteAction
                    }
                    username={username}
                  />

                  <DeleteAllModal
                    onClose={() => setDeleteAllModal(false)}
                    showDeleteAllModal={showDeleteAllModal}
                    table={selectedTable}
                    action={
                      selectedTable === "Deleted Quotations"
                        ? deleteAllAction
                        : softDeleteAllAction
                    }
                    username={username}
                  />

                  <DynamicTable
                    data={filteredDeletedData}
                    canEdit={false}
                    canCreate={false}
                    canRestore={true}
                    setRestoreRowModal={setRestoreRowModal}
                    setRestoreAllModal={setRestoreAllModal}
                    setDeleteRowModal={setDeleteRowModal}
                    setDeleteAllModal={setDeleteAllModal}
                    setSelectedRow={setSelectedRow}
                    table={"Deleted Quotations"}
                    setSelectedTable={setSelectedTable}
                  />
                </>
              )}
            </Collapse>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Quotations;
