import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { DrawerHeader, AppBar, Drawer } from "./DashboardStyles";
import { Provider } from "react-redux";
import store from "../store/store";

import {
  Box,
  Typography,
  Button,
  IconButton,
  Divider,
  Toolbar,
  ListItemIcon,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
  CssBaseline,
} from "@mui/material";

import {
  Menu,
  ChevronLeft,
  ChevronRight,
  KeyboardArrowRight,
  ExpandMore,
  ExpandLess,
  Cottage,
  FormatListBulleted,
  ContentPaste,
  DesignServices,
  PendingActions,
  PostAdd,
  Collections,
  TableView,
  ContactPhone,
} from "@mui/icons-material";

import { DecodeUserRole } from "../hooks/userData";

//homepage tables
import TitleBanner from "./home/TitleBanner";
import ShutterBanner from "./home/ShutterBanner";
import Partners from "./home/Partners";

//services tables
import ServicesBanner from "./services/ServicesBanner";
import Services from "./services/Services";

//projects tables
import Projects from "./projects/Projects";

//addons tables
import AddonsTopBanner from "./addons/TopBanner";
import Addons from "./addons/Addons";
import AddonsBottomBanner from "./addons/BottomBanner";

//whatisnew tables
import Photos from "./whatIsNew/Photos";
import Videos from "./whatIsNew/Videos";

//quotation tables
import QuotationForms from "./quotations/quotations";

//footer tables
import Socials from "./footer/socials";
import Contacts from "./footer/contacts";
import Addresses from "./footer/addresses";

//logs tables
import Logs from "./logs/Logs";

function Dashboard({ onLogout, username }) {
  const theme = useTheme();
  const userRole = DecodeUserRole();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [categories, setCategories] = useState({
    home: false,
    services: false,
    projects: false,
    addons: false,
    whatIsNew: false,
    quotationForms: false,
    footer: false,
    logs: false,
  });

  const [categoryColors, setCategoryColors] = useState({
    home: "",
    services: "",
    projects: "",
    addons: "",
    whatIsNew: "",
    quotationForms: "",
    footer: "",
    logs: "",
  });

  const [selectedComponent, setSelectedComponent] = useState(null);
  const [selectedComponentIndices, setSelectedComponentIndices] = useState({
    home: null,
    services: null,
    projects: null,
    addons: null,
    whatIsNew: null,
    quotationForms: null,
    footer: null,
    logs: null,
  });

  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    window.location.reload();
    onLogout();
  };

  const categoryExpandArrow = (category) =>
    category ? <ExpandLess /> : <ExpandMore />;

  const setIconColor = (category, color) => {
    setCategoryColors((prevColors) => ({
      ...prevColors,
      [category]: color,
    }));
  };

  const handleCategoryToggle = (category) => {
    setCategories((prevCategories) => ({
      ...prevCategories,
      [category]: !prevCategories[category],
    }));

    setIconColor(
      category,
      !categories[category] ? theme.palette.primary.main : ""
    );
  };

  const handleClickComponent = (component, index, category) => {
    setSelectedComponent(component);
    setSelectedComponentIndices(() => ({
      home: category === "home" ? index : null,
      services: category === "services" ? index : null,
      projects: category === "projects" ? index : null,
      addons: category === "addons" ? index : null,
      whatIsNew: category === "whatIsNew" ? index : null,
      quotationForms: category === "quotationForms" ? index : null,
      footer: category === "footer" ? index : null,
      logs: category === "logs" ? index : null,
    }));
  };

  const renderComponent = () => {
    if (!selectedComponent) {
      return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Welcome to the Dashboard
          </Typography>
          <Typography paragraph>
            Select an option from the list to load a component.
          </Typography>
        </Box>
      );
    }

    return (
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {
          {
            //homepage
            "Title Banner": <TitleBanner />,
            "Shutter Banner": <ShutterBanner />,
            Partners: <Partners />,

            //services
            "Services Banner": <ServicesBanner />,
            Services: <Services />,

            //projects
            Projects: <Projects />,

            //addons
            "Top Banner": <AddonsTopBanner />,
            Addons: <Addons />,
            "Bottom Banner": <AddonsBottomBanner />,

            //what is new
            Photos: <Photos />,
            Videos: <Videos />,

            //quotations
            Quotations: <QuotationForms />,

            //footer
            Socials: <Socials />,
            Contacts: <Contacts />,
            Addresses: <Addresses />,

            //logs
            Logs: <Logs />,
          }[selectedComponent]
        }
      </Box>
    );
  };

  const handleClickHeader = () => {
    setSelectedComponent(null);
    const updatedCategories = { ...categories };
    const updatedCategoryColors = { ...categoryColors };

    for (const category in updatedCategories) {
      if (Object.hasOwnProperty.call(updatedCategories, category)) {
        updatedCategories[category] = false;
        updatedCategoryColors[category] = "";
      }
    }

    setCategories(updatedCategories);
    setCategoryColors(updatedCategoryColors);
  };

  let categoriesList = {
    home: {
      text: "Home",
      icon: <Cottage />,
      items: ["Title Banner", "Shutter Banner", "Partners"],
    },
    services: {
      text: "Services",
      icon: <DesignServices />,
      items: ["Services Banner", "Services"],
    },
    projects: {
      text: "Projects",
      icon: <PendingActions />,
      items: ["Projects"],
    },
    addons: {
      text: "Addons",
      icon: <PostAdd />,
      items: ["Top Banner", "Addons", "Bottom Banner"],
    },
    whatIsNew: {
      text: "What's New",
      icon: <Collections />,
      items: ["Photos", "Videos"],
    },
    quotationForms: {
      text: "Quotations",
      icon: <TableView />,
      items: ["Quotations"],
    },
    footer: {
      text: "Footer",
      icon: <ContactPhone />,
      items: ["Socials", "Contacts", "Addresses"],
    },
  };

  if (userRole === "superadmin") {
    const logsCategory = {
      text: "Logs",
      icon: <ContentPaste />,
      items: ["Logs"],
    };

    categoriesList = {
      ...categoriesList,
      logs: logsCategory,
    };
  }

  const CategoryListItem = ({
    categoryName,
    categoryData,
    selectedComponentIndices,
    selectedComponent,
    drawerOpen,
    handleClickComponent,
    categoryExpandArrow,
  }) => {
    const { text, icon, items } = categoryData;

    return (
      <>
        <List>
          <ListItemButton
            onClick={() => {
              handleCategoryToggle(categoryName);
              categoryExpandArrow(categories[categoryName]);
            }}
          >
            <ListItemIcon sx={{ color: categoryColors[categoryName] }}>
              {icon}
            </ListItemIcon>
            <ListItemText primary={text} sx={{ opacity: drawerOpen ? 1 : 0 }} />
            {categoryExpandArrow(categories[categoryName])}
          </ListItemButton>
          <Collapse in={categories[categoryName]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {items.map((text, index) => (
                <ListItem key={text} disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: drawerOpen ? "initial" : "center",
                      px: 2.5,
                      outline:
                        selectedComponent &&
                        index === selectedComponentIndices[categoryName]
                          ? "1px solid #1976d2"
                          : "none",
                      backgroundColor:
                        selectedComponent &&
                        index === selectedComponentIndices[categoryName]
                          ? "#eeeeee"
                          : "none",
                      position: "relative",
                    }}
                    onClick={() =>
                      handleClickComponent(text, index, categoryName)
                    }
                  >
                    {selectedComponent &&
                      index === selectedComponentIndices[categoryName] &&
                      drawerOpen && (
                        <KeyboardArrowRight
                          sx={{
                            position: "absolute",
                            right: 8,
                          }}
                          color="primary"
                        />
                      )}
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: drawerOpen ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {<FormatListBulleted />}
                    </ListItemIcon>
                    <ListItemText
                      primary={text}
                      sx={{ opacity: drawerOpen ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
      </>
    );
  };

  return (
    <Provider store={store}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={drawerOpen}>
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setDrawerOpen(true)}
              edge="start"
              sx={{
                marginRight: 5,
                ...(drawerOpen && { display: "none" }),
              }}
            >
              <Menu />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Shutter Studio
            </Typography>
            <Box sx={{ display: "flex", gap: "1em", alignItems: "center" }}>
              <Typography>{username.toUpperCase()}</Typography>
              <Button
                variant="contained"
                onClick={handleLogout}
                sx={{
                  backgroundColor: "#d32f2f",
                  color: "white",
                  transition: "0.5s all",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "#d32f2f",
                    outline: "1px solid #d32f2f",
                  },
                }}
              >
                Logout
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={drawerOpen}>
          <DrawerHeader
            style={{
              backgroundImage: `url(/images/nameImg.png)`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              cursor: "pointer",
            }}
            onClick={() => handleClickHeader()}
          >
            <IconButton onClick={() => setDrawerOpen(false)}>
              {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          {Object.entries(categoriesList).map(
            ([categoryName, categoryData]) => (
              <CategoryListItem
                categoryName={categoryName}
                categoryData={categoryData}
                selectedComponentIndices={selectedComponentIndices}
                selectedComponent={selectedComponent}
                drawerOpen={drawerOpen}
                handleClickComponent={handleClickComponent}
                categoryExpandArrow={categoryExpandArrow}
                key={categoryName}
              />
            )
          )}
          <Divider />
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {renderComponent()}
        </Box>
      </Box>
    </Provider>
  );
}
export default Dashboard;
