import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    projectsLoading(state) {
      state.loading = true;
      state.error = null;
    },
    projectsError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setProjectsList(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { projectsLoading, projectsError, setProjectsList } =
  projectsSlice.actions;

export default projectsSlice.reducer;
