import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const addonsSlice = createSlice({
  name: "addons",
  initialState,
  reducers: {
    addonsLoading(state) {
      state.loading = true;
      state.error = null;
    },
    addonsError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setAddonsList(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { addonsLoading, addonsError, setAddonsList } = addonsSlice.actions;

export default addonsSlice.reducer;
