import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";

export default function RestoreAllForm({
  onClose,
  showRestoreAllModal,
  table,
  type,
  action,
  username,
}) {
  const dispatch = useDispatch();
  const handleRestoreAll = () => {
    dispatch(action(table, type, username));
    onClose();
  };
  return (
    <Box>
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={showRestoreAllModal}
        onClose={onClose}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "2em",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to restore <b>ALL</b> items in {table}?
          </Typography>
          <Box style={{ display: "flex", gap: "1em" }}>
            <Button
              variant="contained"
              color="error"
              onClick={handleRestoreAll}
              sx={{
                transition: "0.5s all",
                "&:hover": {
                  outline: "1px solid #d32f2f",
                  backgroundColor: "white",
                  color: "#d32f2f",
                },
              }}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onClose}
              sx={{
                transition: "0.5s all",
                "&:hover": {
                  outline: "1px solid #1976d2",
                  backgroundColor: "white",
                  color: "#1976d2",
                },
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
