import React, { useEffect, useState } from "react";
import DynamicTable from "../../components/DynamicTable";
import ImageModal from "../../modals/ImageModal";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import ErrorMessage from "../../components/ErrorMessage";
import CreateModal from "../../modals/CreateModal";
import EditModal from "../../modals/EditModal";
import DeleteRowModal from "../../modals/DeleteRowModal";
import DeleteAllModal from "../../modals/DeleteAllModal";
import RestoreRowModal from "../../modals/RestoreRowModal";
import RestoreAllModal from "../../modals/RestoreAllModal";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import {
  getAction,
  createAction,
  updateAction,
  softDeleteAction,
  softDeleteAllAction,
  deleteRowAction,
  deleteAllAction,
  restoreRowAction,
  restoreAllAction,
} from "../../store/actions/home";
import { DecodeUserRole, DecodeUsername } from "../../hooks/userData";
import { Box, Button, Collapse } from "@mui/material";
import moment from "moment";

export default function TitleBanner() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.home.data);
  const loading = useSelector((state) => state.home.loading);
  const error = useSelector((state) => state.home.error);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showCreateModal, setCreateModal] = useState(false);
  const [showEditModal, setEditModal] = useState(false);
  const [showDeleteRowModal, setDeleteRowModal] = useState(false);
  const [showDeleteAllModal, setDeleteAllModal] = useState(false);
  const [showRestoreRowModal, setRestoreRowModal] = useState(false);
  const [showRestoreAllModal, setRestoreAllModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedTable, setSelectedTable] = useState(false);
  const [showDeletedTables, setShowDeletedTables] = useState(false);
  const userRole = DecodeUserRole();
  const username = DecodeUsername();

  useEffect(() => {
    dispatch(getAction());
  }, [dispatch]);

  const mediaActiveData = data?.staticPages?.title
    ?.filter((item) => !item.isDeleted)
    .map((item) => {
      const {
        createdAt,
        updatedAt,
        isDeleted,
        deletedAt,
        deletedBy,
        translations,
        activityImages,
        activityVideos,
        ...rest
      } = item;

      const translationObject = translations?.reduce((acc, translation) => {
        acc[translation.fieldName] = translation.translatedValue;
        return acc;
      }, {});

      return { ...rest, ...translationObject };
    });

  const mediaDeletedData = data?.staticPages?.title
    ?.filter((item) => item.isDeleted)
    .map((item) => {
      const {
        createdAt,
        updatedAt,
        isDeleted,
        deletedAt,
        deletedBy,
        translations,
        activityImages,
        activityVideos,
        ...rest
      } = item;

      const translationObject = translations?.reduce((acc, translation) => {
        acc[translation.fieldName] = translation.translatedValue;
        return acc;
      }, {});

      const formattedDeletedAt = moment(deletedAt).format("DD/MM/YYYY hh:mm A");

      return {
        ...rest,
        ...translationObject,
        deletedAt: formattedDeletedAt,
      };
    });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "5em" }}>
      {(userRole === "admin" || userRole === "superadmin") && (
        <Box>
          {loading ? (
            <Loader />
          ) : error ? (
            <ErrorMessage message={error} />
          ) : (
            <>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "5em" }}
              >
                <Box>
                  <CreateModal
                    onClose={() => setCreateModal(false)}
                    showCreateModal={showCreateModal}
                    table={selectedTable}
                    action={createAction}
                    username={username}
                    hasVideo={true}
                  />

                  <EditModal
                    onClose={() => setEditModal(false)}
                    showEditModal={showEditModal}
                    table={selectedTable}
                    action={updateAction}
                    selectedRow={selectedRow}
                    username={username}
                    hasVideo={true}
                  />

                  <DynamicTable
                    data={mediaActiveData}
                    setSelectedImage={setSelectedImage}
                    setShowModal={setShowModal}
                    setCreateModal={setCreateModal}
                    setDeleteAllModal={setDeleteAllModal}
                    setDeleteRowModal={setDeleteRowModal}
                    setEditModal={setEditModal}
                    setSelectedRow={setSelectedRow}
                    table={"Title Banner"}
                    setSelectedTable={setSelectedTable}
                    getAction={getAction}
                    hasLang={true}
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
      )}
      {userRole === "superadmin" && (
        <Box>
          <Button onClick={() => setShowDeletedTables(!showDeletedTables)}>
            {showDeletedTables ? "Hide Deleted Tables" : "Show Deleted Tables"}{" "}
            {showDeletedTables ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Button>

          <Collapse in={showDeletedTables}>
            {loading ? (
              <Loader />
            ) : error ? (
              <ErrorMessage message={error} />
            ) : (
              <>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "5em" }}
                >
                  <Box>
                    <RestoreRowModal
                      onClose={() => setRestoreRowModal(false)}
                      showRestoreRowModal={showRestoreRowModal}
                      id={selectedRow?.id}
                      table={selectedTable}
                      action={restoreRowAction}
                      username={username}
                    />

                    <RestoreAllModal
                      onClose={() => setRestoreAllModal(false)}
                      showRestoreAllModal={showRestoreAllModal}
                      table={selectedTable}
                      action={restoreAllAction}
                      username={username}
                    />

                    <DeleteRowModal
                      onClose={() => setDeleteRowModal(false)}
                      showDeleteRowModal={showDeleteRowModal}
                      id={selectedRow?.id}
                      table={selectedTable}
                      action={
                        selectedTable === "Deleted Media"
                          ? deleteRowAction
                          : softDeleteAction
                      }
                      username={username}
                    />

                    <DeleteAllModal
                      onClose={() => setDeleteAllModal(false)}
                      showDeleteAllModal={showDeleteAllModal}
                      table={selectedTable}
                      action={
                        selectedTable === "Deleted Media"
                          ? deleteAllAction
                          : softDeleteAllAction
                      }
                      username={username}
                    />

                    <ImageModal
                      image={selectedImage}
                      onClose={() => setShowModal(false)}
                      showModal={showModal}
                    />

                    <DynamicTable
                      data={mediaDeletedData}
                      canEdit={false}
                      canCreate={false}
                      canRestore={true}
                      setSelectedImage={setSelectedImage}
                      setShowModal={setShowModal}
                      setRestoreRowModal={setRestoreRowModal}
                      setRestoreAllModal={setRestoreAllModal}
                      setDeleteRowModal={setDeleteRowModal}
                      setDeleteAllModal={setDeleteAllModal}
                      setSelectedRow={setSelectedRow}
                      table={"Deleted Media"}
                      setSelectedTable={setSelectedTable}
                      getAction={getAction}
                      hasLang={true}
                    />
                  </Box>
                </Box>
              </>
            )}
          </Collapse>
        </Box>
      )}
    </Box>
  );
}
