import {
  quotationsLoading,
  quotationsError,
  setQuotationsList,
} from "../../slices/quotationsSlice.js";
import {
  getService,
  deleteAllService,
  deleteRowService,
  softDeleteService,
  softDeleteAllService,
  restoreRowService,
  restoreAllService,
} from "../../services/quotations.js";

export const getAction = () => async (dispatch) => {
  dispatch(quotationsLoading());
  try {
    const response = await getService();
    if (response?.error) {
      dispatch(quotationsError(response.error));
      return;
    } else {
      dispatch(setQuotationsList(response));
      return;
    }
  } catch (error) {
    dispatch(quotationsError(error.message));
    return;
  }
};

export const softDeleteAction = (table, id, username) => async (dispatch) => {
  dispatch(quotationsLoading());
  try {
    const response = await softDeleteService(table, id, username);
    if (response?.message) {
      dispatch(getAction());
      return;
    } else {
      dispatch(
        quotationsError(
          `Failed to softdelete row with ID ${id} in ${table} table`
        )
      );
      return;
    }
  } catch (error) {
    dispatch(quotationsError(error.message));
    return;
  }
};

export const softDeleteAllAction =
  (table, type, username) => async (dispatch) => {
    dispatch(quotationsLoading());
    try {
      const response = await softDeleteAllService(table, type, username);
      if (response?.message) {
        dispatch(getAction());
        return;
      } else {
        dispatch(
          quotationsError(
            `Failed to delete records in ${table} table of type ${type}`
          )
        );
        return;
      }
    } catch (error) {
      dispatch(quotationsError(error.message));
      return;
    }
  };

export const deleteRowAction = (table, id, username) => async (dispatch) => {
  dispatch(quotationsLoading());
  try {
    const response = await deleteRowService(table, id, username);
    if (response?.message) {
      dispatch(getAction());
      return;
    } else {
      dispatch(
        quotationsError(`Failed to delete row with ID ${id} in table ${table}`)
      );
      return;
    }
  } catch (error) {
    dispatch(quotationsError(error.message));
    return;
  }
};

export const deleteAllAction = (table, type, username) => async (dispatch) => {
  dispatch(quotationsLoading());
  try {
    const response = await deleteAllService(table, type, username);
    if (response?.message) {
      dispatch(getAction());
      return;
    } else {
      dispatch(
        quotationsError(
          `Failed to delete records in ${table} table of type ${type}`
        )
      );
      return;
    }
  } catch (error) {
    dispatch(quotationsError(error.message));
    return;
  }
};

export const restoreRowAction = (table, id, username) => async (dispatch) => {
  dispatch(quotationsLoading());
  try {
    const response = await restoreRowService(table, id, username);
    if (response?.message) {
      dispatch(getAction());
      return;
    } else {
      dispatch(
        quotationsError(`Failed to recover row with ID ${id} in ${table} table`)
      );
      return;
    }
  } catch (error) {
    dispatch(quotationsError(error.message));
    return;
  }
};

export const restoreAllAction = (table, type, username) => async (dispatch) => {
  dispatch(quotationsLoading());
  try {
    const response = await restoreAllService(table, type, username);
    if (response?.message) {
      dispatch(getAction());
      return;
    } else {
      dispatch(
        quotationsError(
          `Failed to recover records in ${table} table of type ${type}`
        )
      );
      return;
    }
  } catch (error) {
    dispatch(quotationsError(error.message));
    return;
  }
};
