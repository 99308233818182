import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const footerSlice = createSlice({
  name: "footer",
  initialState,
  reducers: {
    footerLoading(state) {
      state.loading = true;
      state.error = null;
    },
    footerError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setFooterList(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { footerLoading, footerError, setFooterList } =
footerSlice.actions;

export default footerSlice.reducer;
