import React, { useEffect, useState } from "react";
import DynamicTable from "../../components/DynamicTable";
import ImageModal from "../../modals/ImageModal";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import ErrorMessage from "../../components/ErrorMessage";
import CreateModal from "../../modals/CreateModal";
import EditModal from "../../modals/EditModal";
import DeleteRowModal from "../../modals/DeleteRowModal";
import DeleteAllModal from "../../modals/DeleteAllModal";
import RestoreRowModal from "../../modals/RestoreRowModal";
import RestoreAllModal from "../../modals/RestoreAllModal";
import { Box, Button, Collapse } from "@mui/material";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import {
  getAction,
  createAction,
  updateAction,
  softDeleteAction,
  softDeleteAllAction,
  deleteRowAction,
  deleteAllAction,
  restoreRowAction,
  restoreAllAction,
} from "../../store/actions/whatIsNew";
import { DecodeUserRole, DecodeUsername } from "../../hooks/userData";
const moment = require("moment");

const Photos = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.whatIsNew.data);
  const loading = useSelector((state) => state.whatIsNew.loading);
  const error = useSelector((state) => state.whatIsNew.error);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showCreateModal, setCreateModal] = useState(false);
  const [showEditModal, setEditModal] = useState(false);
  const [showDeleteRowModal, setDeleteRowModal] = useState(false);
  const [showDeleteAllModal, setDeleteAllModal] = useState(false);
  const [showRestoreRowModal, setRestoreRowModal] = useState(false);
  const [showRestoreAllModal, setRestoreAllModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [selectedTable, setSelectedTable] = useState(false);
  const [showDeletedTables, setShowDeletedTables] = useState(false);
  const userRole = DecodeUserRole();
  const username = DecodeUsername();

  useEffect(() => {
    dispatch(getAction());
  }, [dispatch]);

  const servicesActiveData = data?.gallery?.photos
    ?.filter((item) => !item.isDeleted)
    .map((item) => {
      const {
        createdAt,
        updatedAt,
        isDeleted,
        deletedAt,
        deletedBy,
        type,
        ...rest
      } = item;
      return rest;
    });

  const servicesDeletedData = data?.gallery?.photos
    ?.filter((item) => item.isDeleted)
    .map((item) => {
      const { createdAt, updatedAt, isDeleted, deletedAt, type, ...rest } =
        item;

      const formattedDeletedAt = moment(deletedAt).format("DD/MM/YYYY hh:mm A");

      return {
        ...rest,
        deletedAt: formattedDeletedAt,
      };
    });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "5em" }}>
      {(userRole === "admin" || userRole === "superadmin") && (
        <Box>
          {loading ? (
            <Loader />
          ) : error ? (
            <ErrorMessage message={error} />
          ) : (
            <>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "5em" }}
              >
                <Box>
                  <CreateModal
                    onClose={() => setCreateModal(false)}
                    showCreateModal={showCreateModal}
                    table={selectedTable}
                    action={createAction}
                    username={username}
                    hasTitle={false}
                    hasDescription={false}
                    type={"photo"}
                    hasId
                  />

                  <EditModal
                    onClose={() => setEditModal(false)}
                    showEditModal={showEditModal}
                    table={selectedTable}
                    action={updateAction}
                    selectedRow={selectedRow}
                    username={username}
                    hasTitle={false}
                    hasDescription={false}
                    type={"photo"}
                    hasId
                  />

                  <DynamicTable
                    data={servicesActiveData}
                    setSelectedImage={setSelectedImage}
                    setShowModal={setShowModal}
                    setCreateModal={setCreateModal}
                    setDeleteAllModal={setDeleteAllModal}
                    setDeleteRowModal={setDeleteRowModal}
                    setEditModal={setEditModal}
                    setSelectedRow={setSelectedRow}
                    table={"Photos"}
                    setSelectedTable={setSelectedTable}
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
      )}
      {userRole === "superadmin" && (
        <Box>
          <Button onClick={() => setShowDeletedTables(!showDeletedTables)}>
            {showDeletedTables ? "Hide Deleted Tables" : "Show Deleted Tables"}{" "}
            {showDeletedTables ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Button>

          <Collapse in={showDeletedTables}>
            {loading ? (
              <Loader />
            ) : error ? (
              <ErrorMessage message={error} />
            ) : (
              <>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "5em" }}
                >
                  <Box>
                    <RestoreRowModal
                      onClose={() => setRestoreRowModal(false)}
                      showRestoreRowModal={showRestoreRowModal}
                      id={selectedRow?.id}
                      table={selectedTable}
                      action={restoreRowAction}
                      username={username}
                    />

                    <RestoreAllModal
                      onClose={() => setRestoreAllModal(false)}
                      showRestoreAllModal={showRestoreAllModal}
                      table={selectedTable}
                      action={restoreAllAction}
                      username={username}
                      type={"photo"}
                    />

                    <DeleteRowModal
                      onClose={() => setDeleteRowModal(false)}
                      showDeleteRowModal={showDeleteRowModal}
                      id={selectedRow?.id}
                      table={selectedTable}
                      action={
                        selectedTable === "Deleted Photos"
                          ? deleteRowAction
                          : softDeleteAction
                      }
                      username={username}
                    />

                    <DeleteAllModal
                      onClose={() => setDeleteAllModal(false)}
                      showDeleteAllModal={showDeleteAllModal}
                      table={selectedTable}
                      action={
                        selectedTable === "Deleted Photos"
                          ? deleteAllAction
                          : softDeleteAllAction
                      }
                      username={username}
                      type={"photo"}
                    />

                    <ImageModal
                      image={selectedImage}
                      onClose={() => setShowModal(false)}
                      showModal={showModal}
                    />

                    <DynamicTable
                      data={servicesDeletedData}
                      canEdit={false}
                      canCreate={false}
                      canRestore={true}
                      setRestoreRowModal={setRestoreRowModal}
                      setRestoreAllModal={setRestoreAllModal}
                      setDeleteRowModal={setDeleteRowModal}
                      setDeleteAllModal={setDeleteAllModal}
                      setSelectedRow={setSelectedRow}
                      table={"Deleted Photos"}
                      setSelectedTable={setSelectedTable}
                    />
                  </Box>
                </Box>
              </>
            )}
          </Collapse>
        </Box>
      )}
    </Box>
  );
};

export default Photos;
