import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const quotationsSlice = createSlice({
  name: "quotations",
  initialState,
  reducers: {
    quotationsLoading(state) {
      state.loading = true;
      state.error = null;
    },
    quotationsError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setQuotationsList(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { quotationsLoading, quotationsError, setQuotationsList } =
quotationsSlice.actions;

export default quotationsSlice.reducer;
