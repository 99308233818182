import React, { useEffect, useState } from "react";
import DynamicTable from "../../components/DynamicTable";
import ImageModal from "../../modals/ImageModal";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import ErrorMessage from "../../components/ErrorMessage";
import CreateModal from "../../modals/CreateModal";
import EditModal from "../../modals/EditModal";
import DeleteAllModal from "../../modals/DeleteAllModal";
import DeleteRowModal from "../../modals/DeleteRowModal";
import RestoreRowModal from "../../modals/RestoreRowModal";
import RestoreAllModal from "../../modals/RestoreAllModal";
import { Box, Button, Collapse } from "@mui/material";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import {
  getAction,
  createAction,
  updateAction,
  softDeleteAction,
  softDeleteAllAction,
  deleteRowAction,
  deleteAllAction,
  restoreRowAction,
  restoreAllAction,
} from "../../store/actions/projects";
import { DecodeUserRole, DecodeUsername } from "../../hooks/userData";
const moment = require("moment");

const Projects = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.projects.data);
  const loading = useSelector((state) => state.projects.loading);
  const error = useSelector((state) => state.projects.error);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showCreateModal, setCreateModal] = useState(false);
  const [showEditModal, setEditModal] = useState(false);
  const [showDeleteRowModal, setDeleteRowModal] = useState(false);
  const [showDeleteAllModal, setDeleteAllModal] = useState(false);
  const [showRestoreRowModal, setRestoreRowModal] = useState(false);
  const [showRestoreAllModal, setRestoreAllModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [selectedTable, setSelectedTable] = useState(false);
  const [showDeletedTables, setShowDeletedTables] = useState(false);
  const userRole = DecodeUserRole();
  const username = DecodeUsername();

  useEffect(() => {
    dispatch(getAction());
  }, [dispatch]);

  const filteredActiveData = data?.projects
    ?.filter((item) => !item.isDeleted)
    .map((item) => {
      const {
        createdAt,
        updatedAt,
        isDeleted,
        deletedBy,
        deletedAt,
        translations,
        ...rest
      } = item;

      const translationObject = translations?.reduce((acc, translation) => {
        acc[translation.fieldName] = translation.translatedValue;
        return acc;
      }, {});

      return { ...rest, ...translationObject };
    });

  const filteredDeletedData = data?.projects
    ?.filter((item) => item.isDeleted)
    .map((item) => {
      const {
        createdAt,
        updatedAt,
        isDeleted,
        deletedAt,
        translations,
        ...rest
      } = item;

      const formattedDeletedAt = moment(deletedAt).format("DD/MM/YYYY hh:mm A");

      const translationObject = translations?.reduce((acc, translation) => {
        acc[translation.fieldName] = translation.translatedValue;
        return acc;
      }, {});

      return {
        ...rest,
        ...translationObject,
        deletedAt: formattedDeletedAt,
      };
    });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "5em" }}>
      {(userRole === "admin" || userRole === "superadmin") && (
        <>
          <Box>
            {loading ? (
              <Loader />
            ) : error ? (
              <ErrorMessage message={error} />
            ) : (
              <>
                <CreateModal
                  onClose={() => setCreateModal(false)}
                  showCreateModal={showCreateModal}
                  table={selectedTable}
                  action={createAction}
                  username={username}
                  hasDescription={false}
                />

                <EditModal
                  onClose={() => setEditModal(false)}
                  showEditModal={showEditModal}
                  selectedRow={selectedRow}
                  table={selectedTable}
                  image={selectedImage}
                  action={updateAction}
                  username={username}
                  hasDescription={false}
                />

                <DynamicTable
                  data={filteredActiveData}
                  setSelectedImage={setSelectedImage}
                  setShowModal={setShowModal}
                  setCreateModal={setCreateModal}
                  setDeleteAllModal={setDeleteAllModal}
                  setDeleteRowModal={setDeleteRowModal}
                  setSelectedRow={setSelectedRow}
                  setEditModal={setEditModal}
                  table={"Projects"}
                  setSelectedTable={setSelectedTable}
                  getAction={getAction}
                  hasLang={true}
                />
              </>
            )}
          </Box>
        </>
      )}
      {userRole === "superadmin" && (
        <>
          <Box>
            <Button onClick={() => setShowDeletedTables(!showDeletedTables)}>
              {showDeletedTables
                ? "Hide Deleted Tables"
                : "Show Deleted Tables"}{" "}
              {showDeletedTables ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </Button>

            <Collapse in={showDeletedTables}>
              {loading ? (
                <Loader />
              ) : error ? (
                <ErrorMessage message={error} />
              ) : (
                <>
                  <RestoreRowModal
                    onClose={() => setRestoreRowModal(false)}
                    showRestoreRowModal={showRestoreRowModal}
                    id={selectedRow?.id}
                    table={selectedTable}
                    action={restoreRowAction}
                    username={username}
                  />

                  <RestoreAllModal
                    onClose={() => setRestoreAllModal(false)}
                    showRestoreAllModal={showRestoreAllModal}
                    table={selectedTable}
                    action={restoreAllAction}
                    username={username}
                  />

                  <DeleteRowModal
                    onClose={() => setDeleteRowModal(false)}
                    showDeleteRowModal={showDeleteRowModal}
                    id={selectedRow?.id}
                    table={selectedTable}
                    action={
                      selectedTable === "Deleted Projects"
                        ? deleteRowAction
                        : softDeleteAction
                    }
                    username={username}
                  />

                  <DeleteAllModal
                    onClose={() => setDeleteAllModal(false)}
                    showDeleteAllModal={showDeleteAllModal}
                    table={selectedTable}
                    action={
                      selectedTable === "Deleted Projects"
                        ? deleteAllAction
                        : softDeleteAllAction
                    }
                    username={username}
                  />

                  <ImageModal
                    image={selectedImage}
                    onClose={() => setShowModal(false)}
                    showModal={showModal}
                  />

                  <DynamicTable
                    data={filteredDeletedData}
                    canEdit={false}
                    canCreate={false}
                    canRestore={true}
                    setSelectedImage={setSelectedImage}
                    setShowModal={setShowModal}
                    setRestoreRowModal={setRestoreRowModal}
                    setRestoreAllModal={setRestoreAllModal}
                    setDeleteRowModal={setDeleteRowModal}
                    setDeleteAllModal={setDeleteAllModal}
                    setSelectedRow={setSelectedRow}
                    table={"Deleted Projects"}
                    setSelectedTable={setSelectedTable}
                    getAction={getAction}
                    hasLang={true}
                  />
                </>
              )}
            </Collapse>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Projects;
