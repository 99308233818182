import axios from "axios";

export const getService = async (language) => {
  const storedLanguage = localStorage.getItem("selectedLanguage");
  return await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/footer?language=${
        language ? language : storedLanguage ? storedLanguage : "en"
      }`
    )
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Error retrieving Footer data: ${error.message}`);
    });
};

export const createService = async (formData) => {
  try {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/footer?language=${
        storedLanguage ? storedLanguage : "en"
      }`,
      formData
    );

    await logService(formData.get("username"), "Create", formData.get("table"));

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateService = async (formData) => {
  try {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/footer/update/${formData.get(
        "table"
      )}/${formData.get("id")}?language=${
        storedLanguage ? storedLanguage : "en"
      }`,
      formData
    );

    await logService(formData.get("username"), "Update", formData.get("table"));

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const softDeleteService = async (table, id, username) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/footer/softdelete/${table}/${id}`,
      { username }
    );

    await logService(username, "Soft Delete", table);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const softDeleteAllService = async (table, type, username) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/footer/softdeleteAll`,
      null,
      {
        params: { table, type, username },
      }
    );

    await logService(username, "Soft Delete All", table);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteRowService = async (table, id, username) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/footer/delete/${table}/${id}`,
      {
        params: { table, id },
      }
    );

    await logService(username, "Perm Delete", table);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteAllService = async (table, type, username) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/footer/deleteAll`,
      {
        params: { table, type },
      }
    );

    await logService(username, "Perm Delete All", table);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const restoreRowService = async (table, id, username) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/footer/restore/${table}/${id}`
    );

    await logService(username, "Restore", table);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const restoreAllService = async (table, type, username) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/footer/restoreAll`,
      null,
      {
        params: { table, type },
      }
    );

    await logService(username, "Restore All", table);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const logService = async (username, actionType, tableName) => {
  try {
    await axios.post(`${process.env.REACT_APP_BASE_URL}/logs`, {
      username,
      actionType,
      tableName,
      timestamp: new Date(),
    });
  } catch (error) {
    throw error;
  }
};
