import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import ButtonContained from "../components/ButtonPrimaryContained";
import ButtonOutlined from "../components/ButtonPrimaryOutlined";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TextField from "@mui/material/TextField";
import { Input, InputLabel } from "@mui/material";

export default function EditForm({
  onClose,
  showEditModal,
  table,
  selectedRow,
  action,
  username,
  type,
  hasTitle = true,
  hasDescription = true,
  hasImage = true,
  hasVideo = false,
  hasEmail = false,
  hasPhone = false,
  hasAddress = false,
  hasId = false,
}) {
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState("");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [url, setURL] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [serviceId, setServiceId] = useState("");

  useEffect(() => {
    if (selectedImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    } else {
    }
  }, [selectedImage]);

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleClose = () => {
    setSelectedImage("");
    setImagePreview("");
    onClose();
  };
  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("table", table);
    formData.append("id", selectedRow?.id);
    if (type) {
      formData.append("type", type);
    }
    if (hasTitle && title) {
      formData.append("title", title);
    }
    if (hasDescription && description) {
      formData.append("description", description);
    }
    if (hasImage && selectedImage) {
      formData.append("image", selectedImage);
    }
    if (hasVideo && url) {
      formData.append("url", url);
    }
    if (hasEmail && email) {
      formData.append("email", email);
    }
    if (hasPhone && phone) {
      formData.append("phone", phone);
    }
    if (hasAddress && address) {
      formData.append("address", address);
    }
    if (hasId && serviceId) {
      formData.append("serviceId", serviceId);
    }
    formData.append("username", username);
    dispatch(action(formData));

    setImagePreview("");
    setSelectedImage("");
    onClose();
  };

  return (
    <Modal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={showEditModal}
      onClose={handleClose}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "2em",
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Edit row {selectedRow?.id} in {table}
        </Typography>
        {hasImage &&
          selectedRow?.image &&
          !selectedRow.image.includes("youtube") && (
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <InputLabel
                  htmlFor="upload-input"
                  style={{ cursor: "pointer" }}
                >
                  <CloudUploadIcon color="primary" />
                  <Input
                    id="upload-input"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleImageSelect}
                  />
                </InputLabel>
              }
            >
              <Avatar
                alt=""
                src={
                  imagePreview !== ""
                    ? imagePreview
                    : selectedRow.image || selectedRow.url
                }
                sx={{ width: "100px", height: "100px" }}
              />
            </Badge>
          )}
        {hasId && (
          <TextField
            required
            id="outlined-required"
            label="Service ID"
            defaultValue={selectedRow?.serviceId}
            onChange={(event) => setServiceId(event.target.value)}
            type="number"
          />
        )}
        {hasTitle &&
          selectedRow?.image &&
          !selectedRow.image.includes("youtube") && (
            <TextField
              required
              id="outlined-required"
              label="Title"
              defaultValue={selectedRow?.title}
              onChange={(event) => setTitle(event.target.value)}
            />
          )}
        {hasEmail && (
          <TextField
            required
            id="outlined-required"
            label="Email"
            defaultValue={selectedRow?.email}
            onChange={(event) => setEmail(event.target.value)}
          />
        )}
        {hasPhone && (
          <TextField
            required
            id="outlined-required"
            label="Phone"
            defaultValue={selectedRow?.phone}
            onChange={(event) => setPhone(event.target.value)}
          />
        )}
        {hasAddress && (
          <TextField
            required
            id="outlined-required"
            label="Address"
            defaultValue={selectedRow?.address}
            onChange={(event) => setAddress(event.target.value)}
          />
        )}
        {hasDescription &&
          selectedRow?.image &&
          !selectedRow.image.includes("youtube") && (
            <TextField
              required
              id="outlined-multiline-static"
              label="Description"
              multiline
              rows={4}
              defaultValue={selectedRow?.description}
              onChange={(event) => setDescription(event.target.value)}
            />
          )}
        {hasVideo && selectedRow?.image?.includes("youtube") && (
          <TextField
            required
            id="outlined-required"
            label="URL"
            defaultValue={
              selectedRow?.url ? selectedRow?.url : selectedRow?.image
            }
            onChange={(event) => setURL(event.target.value)}
          />
        )}
        <Box style={{ display: "flex", gap: "1em" }}>
          <ButtonContained
            onClick={handleSubmit}
            label="Submit"
          ></ButtonContained>
          <ButtonOutlined onClick={handleClose} label="Close"></ButtonOutlined>
        </Box>
      </Box>
    </Modal>
  );
}
